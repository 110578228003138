import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { Input, Layout, Typography, DatePicker, ConfigProvider, Select, CollapseProps, Modal, SelectProps, Flex, Table, Button, Dropdown, notification, Space } from 'antd';
const { TextArea } = Input;
const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { confirm } = Modal;

import { getAganistType, getClients, getEmployees, getSourceType, multi, complaintSearch as search, exportAllComplaint as searchExport, canResolveComplaint, canUpdateAssignTo } from '../../../service/httpsCalls'
import "./search.scss";
import Sider from 'antd/es/layout/Sider';
import dayjs from 'dayjs';
import { DownOutlined, ExclamationCircleFilled, RedoOutlined, UserOutlined } from '@ant-design/icons';
import { DateRange } from '../../../types';
import debounce from 'lodash.debounce';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { ColumnFilterItem, FilterValue, Key, SorterResult, TableRowSelection } from 'antd/es/table/interface';
import type { MenuProps, TableColumnsType, TableProps } from 'antd';
import { ColumnDropdown } from '../../../components/common/ColumnDropdown';
import { ItemType, MenuItemType } from 'antd/es/menu/hooks/useItems';
import { toCamelCase } from '../../../helpers/commonHelpers';
interface SearchParams {
    searchText: string,
    searchType: string,
    pageIndex: any,
    pageSize: any,
    statusId: FilterValue | null,
    assignedTo: FilterValue | null,
    complaintDateFrom: any,
    complaintDateTo: any,
    typeId: FilterValue | null,
    againstTypeId: any,
    sourceTypeId: any,
    clientIds: FilterValue | null,
    sortField: string | null,
    sortDirection: string | null,
    dueDateFrom: any,
    dueDateTo: any,
    createdDateFrom: any,
    createdDateTo: any,
}
interface CheckedProps {
    item: string;
    checked: boolean;
}
type NotificationType = 'success' | 'info' | 'warning' | 'error';
export default function Search() {
    const [api, contextHolder] = notification.useNotification();
    const [searchResult, setSearchResult] = useState([])
    const [filteredSearchResult, setFilteredSearchResult] = useState([])
    const [searchError, setSearchError] = useState(false)
    const [searchItem, setSearchItem] = useState('')
    const [loading, setLoading] = useState(false)
    const [searchTypeValue, setSearchTypeValue] = useState('all')
    const [notFound, setNotFound] = useState(false)
    const [dateRange, setDateRange] = useState<DateRange>({ start: null, end: null });
    const [showAssignTo, setShowAssignTo] = useState(false);
    const { RangePicker } = DatePicker;
    const searchTypeOptions = [
        { label: 'All', value: 'all' },
        { label: 'Case Number', value: 'case-number' },
        { label: 'Trip Number', value: 'trip-number' },
        { label: 'Member Id', value: 'member-id' },
        { label: 'Member Name', value: 'member-name' },
        { label: 'Created By', value: 'created-by' },
    ];

    const listRef = useRef<HTMLDivElement>(null);
    const [pageSize, setPageSize] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [startDate, setStartDate] = useState<string | any>('');
    const [endDate, setEndDate] = useState<string | any>('');
    const [typeFilter, setTypeFilter] = useState<FilterValue | null>();
    const [statusFilter, setStatusFilter] = useState<FilterValue | null>([]);
    const [assignedFilter, setAssignedFilter] = useState<FilterValue | null>();
    const [assignedOptions, setAssignedOptions] = useState<ColumnFilterItem[]>([{ value: 0, text: 'Unassigned' }]);
    const baseUrl = window.localStorage.getItem("Referrer");
    const [aganistTypeFilter, setAganistTypeFilter] = useState(0);
    const [sourceTypeFilter, setSourceTypeFilter] = useState(0)
    const [assignedTypeOption, setAssignedTypeOptions] = useState<SelectProps['options']>([{ value: 0, label: ' ' }]);
    const [sourceTypeOption, setSourceTypeOption] = useState<SelectProps['options']>([{ value: 0, label: ' ' }]);
    const [clientFilter, setClientFilter] = useState<FilterValue | null>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [menuVisible, setMenuVisible] = useState(false);
    const [position, setPosition] = useState({
        x: 0,
        y: 0
    });
    const [clientOptions, setClientOptions] = useState<ColumnFilterItem[]>();
    const [selectedEmployee, setSelectedEmployee] = useState<ColumnFilterItem|null>();
    const [assignedToText, setAssignedToText] = useState("Assign To");

    const [selectedStatus, setSelectedStatus] = useState<MenuItemType|null>();
    const [changeStatusText, setChangeStatusText] = useState("Change Status");

    const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
    const [dueStart, setDueStart] = useState<string | any>('');
    const [dueEnd, setDueEnd] = useState<string | any>('');
    const [createdAtStart, setCreatedAtStart] = useState<string | any>('');
    const [createdAtEnd, setCreatedAtEnd] = useState<string | any>('');
    //Using these fields to handle Complaint Date filter issue
    const [tempStartDate, setTempStartDate] = useState<string | any>('');
    const [tempEndDate, setTempEndDate] = useState<string | any>('');
    const [tempDueStart, setTempDueStart] = useState<string | any>('');
    const [tempDueEnd, setTempDueEnd] = useState<string | any>('');
    const [tempCreatedStart, setTempCreatedStart] = useState<string | any>('');
    const [tempCreatedEnd, setTempCreatedEnd] = useState<string | any>('');
    const [dateUpdated, setDateUpdated] = useState(false);
    const [statusItems, setStatusItems] = useState<MenuItemType[]>([]);
    const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
        api[type]({
            message: message,
            description: description
        });
    };

    const useDebounce = (callback: any) => {
        const ref = useRef<any>();

        useEffect(() => {
            ref.current = callback;
        }, [callback]);

        const debouncedCallback = useMemo(() => {
            const func = () => {
                ref.current?.();
            };

            return debounce(func, 1000);
        }, []);

        return debouncedCallback;
    };

    const debouncedSearch = useDebounce(() => {
        let data = {
            searchText: searchItem,
            searchType: searchTypeValue,
            pageIndex: 0,
            pageSize: pageSize,
            statusId: statusFilter?.length == 0 ? null : statusFilter,
            assignedTo: assignedFilter?.length == 0 ? null : assignedFilter,
            complaintDateFrom: startDate?.length === 0 ? null : dayjs(startDate).format('YYYY-MM-DDT00:00:00'),
            complaintDateTo: endDate?.length === 0 ? null : dayjs(endDate).format('YYYY-MM-DDT23:59:59'),
            typeId: typeFilter?.length == 0 ? null : typeFilter,
            againstTypeId: aganistTypeFilter == 0 ? null : aganistTypeFilter,
            sourceTypeId: sourceTypeFilter == 0 ? null : sourceTypeFilter,
            clientIds: clientFilter?.length == 0 ? null : clientFilter,
            sortField: sortedInfo.field,
            sortDirection: sortedInfo.order,
            dueDateFrom: dueStart?.length === 0 ? null : dayjs(dueStart).format('YYYY-MM-DDT00:00:00'),
            dueDateTo: dueEnd?.length === 0 ? null : dayjs(dueEnd).format('YYYY-MM-DDT23:59:59'),
            createdDateFrom: createdAtStart?.length === 0 ? null : dayjs(createdAtStart).format('YYYY-MM-DDT00:00:00'),
            createdDateTo: createdAtEnd?.length === 0 ? null : dayjs(createdAtEnd).format('YYYY-MM-DDT23:59:59')
        }
        setCurrentPage(1);
        executeSearch(data);
    });

    // Extract the API call logic into a function
    const executeSearch = ({ searchText, searchType, pageIndex, pageSize, statusId, assignedTo, complaintDateFrom, complaintDateTo, typeId, againstTypeId, sourceTypeId, clientIds, sortField, sortDirection, dueDateFrom, dueDateTo, createdDateFrom, createdDateTo }: SearchParams) => {

        setLoading(true);
        setNotFound(false);
        console.log("executed")
        search({
            searchText,
            searchType,
            pageIndex,
            pageSize,
            statusId,
            assignedTo,
            complaintDateFrom,
            complaintDateTo,
            typeId,
            againstTypeId,
            sourceTypeId,
            clientIds,
            sortField,
            sortDirection,
            dueDateFrom,
            dueDateTo,
            createdDateFrom,
            createdDateTo
        }, (res: any) => {
            if (res !== 'error') {
                setSearchResult(res.items)
                setFilteredSearchResult(res.items)
                if (res.length == 0) setNotFound(true)
                setSearchError(false)
                setLoading(false)
                setTotalPages(Math.ceil(res.totalRecords / (pageSize / pageSize)));
            } else {
                setSearchError(true)
                setLoading(false)
                setSearchResult([])
                setFilteredSearchResult([])
            }
            setSelectedRowKeys([])
        });
    };


    const debouncedExport = useDebounce(() => {
        openNotificationWithIcon("success", "Export Grievance Started Successfully", "");
        let data = {
            searchText: searchItem,
            searchType: searchTypeValue,
            pageIndex: currentPage,
            pageSize: pageSize,
            statusId: statusFilter?.length == 0 ? null : statusFilter,
            assignedTo: assignedFilter?.length == 0 ? null : assignedFilter,
            complaintDateFrom: startDate?.length === 0 ? null : dayjs(startDate).format('YYYY-MM-DDT00:00:00'),
            complaintDateTo: endDate?.length === 0 ? null : dayjs(endDate).format('YYYY-MM-DDT23:59:59'),
            typeId: typeFilter?.length == 0 ? null : typeFilter,
            againstTypeId: aganistTypeFilter == 0 ? null : aganistTypeFilter,
            sourceTypeId: sourceTypeFilter == 0 ? null : sourceTypeFilter,
            clientIds: clientFilter?.length == 0 ? null : clientFilter,
            sortField: sortedInfo.field,
            sortDirection: sortedInfo.order,
            dueDateFrom: dueStart?.length === 0 ? null : dayjs(dueStart).format('YYYY-MM-DDT00:00:00'),
            dueDateTo: dueEnd?.length === 0 ? null : dayjs(dueEnd).format('YYYY-MM-DDT23:59:59'),
            createdDateFrom: createdAtStart?.length === 0 ? null : dayjs(createdAtStart).format('YYYY-MM-DDT00:00:00'),
            createdDateTo: createdAtEnd?.length === 0 ? null : dayjs(createdAtEnd).format('YYYY-MM-DDT23:59:59')
        }
        executeExport(data);
    });

    // Extract the API call logic into a function
    const executeExport = ({ searchText, searchType, pageIndex, pageSize, statusId, assignedTo, complaintDateFrom, complaintDateTo, typeId, againstTypeId, sourceTypeId, clientIds, sortField, sortDirection, dueDateFrom, dueDateTo, createdDateFrom, createdDateTo }: SearchParams) => {
        setLoading(true);
        setNotFound(false);
        console.log("executed")
        searchExport({
            searchText,
            searchType,
            pageIndex,
            pageSize,
            statusId,
            assignedTo,
            complaintDateFrom,
            complaintDateTo,
            typeId,
            againstTypeId,
            sourceTypeId,
            clientIds,
            sortField,
            sortDirection,
            dueDateFrom,
            dueDateTo,
            createdDateFrom,
            createdDateTo
        }, (res: any) => {               
        });
    };

    function getNextPage(p: any) {
        setLoading(true)
        setNotFound(false)
        let data = {
            searchText: searchItem,
            searchType: searchTypeValue,
            pageIndex: p - 1,
            pageSize: pageSize,
            statusId: statusFilter?.length == 0 ? null : statusFilter,
            assignedTo: assignedFilter?.length == 0 ? null : assignedFilter,
            complaintDateFrom: startDate?.length === 0 ? null : dayjs(startDate).format('YYYY-MM-DDT00:00:00'),
            complaintDateTo: endDate?.length === 0 ? null : dayjs(endDate).format('YYYY-MM-DDT23:59:59'),
            typeId: typeFilter?.length == 0 ? null : typeFilter,
            againstTypeId: aganistTypeFilter == 0 ? null : aganistTypeFilter,
            sourceTypeId: sourceTypeFilter == 0 ? null : sourceTypeFilter,
            clientIds: clientFilter?.length == 0 ? null : clientFilter,
            sortField: sortedInfo.field,
            sortDirection: sortedInfo.order,
            dueDateFrom: dueStart?.length === 0 ? null : dayjs(dueStart).format('YYYY-MM-DDT00:00:00'),
            dueDateTo: dueEnd?.length === 0 ? null : dayjs(dueEnd).format('YYYY-MM-DDT23:59:59'),
            createdDateFrom: createdAtStart?.length === 0 ? null : dayjs(createdAtStart).format('YYYY-MM-DDT00:00:00'),
            createdDateTo: createdAtEnd?.length === 0 ? null : dayjs(createdAtEnd).format('YYYY-MM-DDT23:59:59')
        }
        executeSearch(data);
        //search(data, (res: any) => {
        //    if (res !== 'error') {
        //        setSearchResult(res.items)
        //        setFilteredSearchResult(res.items)
        //        if (res.length == 0) setNotFound(true)
        //        setSearchError(false)
        //        setLoading(false)
        //        setTotalPages(Math.ceil(res.totalRecords / (pageSize / pageSize)));
        //    } else {
        //        setSearchError(true)
        //        setLoading(false)
        //        setSearchResult([])
        //        setFilteredSearchResult([])
        //    }
        //})
    }

    const toPascalCase = (s: string | null | undefined) =>
        s ? s.replace(/(\w)(\w*)/g, (_, p, q) => p.toUpperCase() + q.toLowerCase()) : s;
    function handleSearch(e: any) {
        const searchText = e.key !== "Enter" ? e.target.value : searchItem;
        setSearchItem(e.target.value);
        debouncedSearch();
    }

    function dateRangeFilter(start: any, end: any) {

        const startDate_ = new Date(start);
        const endDate_ = new Date(end);

        let filter = searchResult.filter((item: any, index) => {
            const complaintDate = new Date(item.complaintDate);
            return complaintDate >= startDate_ && complaintDate <= endDate_;
        })

        setFilteredSearchResult(filter)

    }

    //useEffect(() => {

    //    if ((startDate !== "" && endDate !== "") && (new Date(endDate) > new Date(startDate))) {
    //        dateRangeFilter(startDate, endDate)
    //        getNextPage(currentPage);
    //    }
    //}, [endDate, startDate])

    useEffect(() => {
        setCurrentPage(1);
        currentPage > 1 ? setCurrentPage(1) : getNextPage(1);
    }, [searchTypeValue])
    function onChangeSearchType(value: string) {
        setSearchTypeValue(value);
    }

    function handleNavigation(id: string) {

        window.location.href = baseUrl + `/Reservation/Reservation/EditComplaint?complaintInfoId=${id}`;
    }


    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Complaint Date',
            children: <RangePicker
                allowClear={false}
                format={["MM/DD/YYYY"]}
                onChange={(dates: any) => {
                    if (dates) {
                        setStartDate(dates[0]);
                        setEndDate(dates[1]);
                        setCurrentPage(1)
                    }
                }}
                value={[startDate, endDate]}
            />,
        },
        {
            key: '2',
            label: "Type",
            children: <Select
                allowClear
                style={{ width: "100%" }}
                value={typeFilter}
                options={[
                    { value: 0, label: " " },
                    { value: 1, label: "Internal Inquiry" },
                    { value: 2, label: "Standard" },
                    { value: 3, label: "DMHC" },
                    { value: 4, label: "Dissatisfaction" },
                    { value: 5, label: "Urgent" },
                    { value: 6, text: "Accident/Injury/Incident" },
                    { value: 7, text: "Crisis Alert" }
                ]}
                onChange={(v, l) => { setTypeFilter(v), setCurrentPage(1) }}
            />
        },
        {
            key: '3',
            label: "Status",
            children: <Select
                allowClear
                style={{ width: "100%" }}
                options={[
                    { value: 0, label: " " },
                    { value: 1, label: "Open" },
                    { value: 3, label: "Substantiated" },
                    { value: 4, label: "Unsubstantiated" },
                    { value: 5, label: "Indeterminable" },
                    { value: 7, label: "Pending" },
                    { value: 8, label: "In Progress" },
                    { value: 9, label: "Internal Review" },
                    { value: 10, label: "Client Review" },
                    { value: 11, label: "Resolved" },
                    { value: 12, label: "Client Denied" },
                ]}
                onChange={(v, l) => { setStatusFilter(v), setCurrentPage(1) }}
                value={statusFilter}
            />
        },
        {
            key: '4',
            label: "Assigned To",
            children: <Select
                allowClear
                showSearch
                style={{ width: "100%" }}
                value={assignedFilter}
                options={assignedOptions}
                onChange={(v, l) => { setAssignedFilter(v), setCurrentPage(0) }}
                onSearch={(text) => getPanelValue(text)}

            />
        },
        {
            key: '5',
            label: 'Against Type',
            children: <Select
                allowClear
                showSearch
                style={{ width: "100%" }}
                options={assignedTypeOption}
                value={aganistTypeFilter}
                onChange={(value) => { setAganistTypeFilter(value), setCurrentPage(0) }}
                onSearch={() => getAganistTypeDropdownValue()}
            />
        },
        {
            key: '6',
            label: "Source Type",
            children: <Select
                allowClear
                showSearch
                style={{ width: "100%" }}
                options={sourceTypeOption}
                value={sourceTypeFilter}
                onChange={(value) => { setSourceTypeFilter(value), setCurrentPage(0) }}
                onSearch={() => getSourceTypeDropdownValue()}
            />
        }

    ];

    // AganistType dropdown functionality 
    const getAganistTypeDropdownValue = async () => {
        var res = await getAganistType()
        const temp = res?.map((v: any) => {
            return {
                value: v?.id,
                label: v?.name,
                ...v
            }
        })
        let newArray = [...temp];
        newArray.unshift({ value: 0, label: ' ' })
        setAssignedTypeOptions(newArray)
    }

    // SourceType dropdown functionality
    const getSourceTypeDropdownValue = async () => {
        var res = await getSourceType()
        const temp = res?.map((v: any) => {
            return {
                value: v?.id,
                label: v?.name,
                ...v
            }
        })
        let newArray = [...temp];
        newArray.unshift({ value: 0, label: ' ' })
        setSourceTypeOption(newArray)
    }

    function getUserPermissions() {
        canUpdateAssignTo((res) => {
            setShowAssignTo(res ?? false);
        });
        canResolveComplaint((res) => {
            if (!res) {
                setStatusItems(statusMenu.filter(item => !(item.key == 11 || item.key == 10 || item.key == 12)));
            } else {
                setStatusItems(statusMenu);
            }
        });
    }


    useEffect(() => {
        if (listRef.current) {
            const newPageSize = Math.floor(listRef.current.clientHeight / 65);
            setPageSize(newPageSize);
        }
        getUserPermissions();
        getPanelValue('');
        getAganistTypeDropdownValue();
        getSourceTypeDropdownValue();
        getClientOptions();
    }, []);

    useEffect(() => {
        console.log(currentPage);
        if (pageSize != 0)
            getNextPage(currentPage);
    }, [currentPage, pageSize, typeFilter, assignedFilter, statusFilter, aganistTypeFilter, sourceTypeFilter, clientFilter, sortedInfo]);




    //function onPageChange(p: any, ps: any) {

    //    setCurrentPage(p - 1);
    //    //getNextPage(p - 1);
    //    setPageSize(ps);
    //}

    const getPanelValue = async (searchText: string) => {
        var res = await getEmployees(searchText);
        const temp = res?.items?.map((e: any) => {
            return renderItem(e);
        })
        let newArray = [...temp]
        newArray.unshift({ value: 0, text: 'Unassigned' });
        setAssignedOptions(newArray);
    }
    const renderItem = (employee: any) => ({
        value: employee.id,
        text: employee.fullName,
        ...employee
    });

    function resetFilters(e: any): void {

        setEndDate('')
        setStartDate('')
        setStatusFilter([])
        setAssignedFilter(null)
        setTypeFilter(null)
        setAganistTypeFilter(0)
        setSourceTypeFilter(0)



        let data = {
            searchText: searchItem,
            searchType: searchTypeValue,
            pageIndex: currentPage,
            pageSize: pageSize,
            statusId: null,
            assignedTo: null,
            complaintDateFrom: null,
            complaintDateTo: null,
            typeId: null,
            againstTypeId: null,
            sourceTypeId: null
        }
        search(data, (res: any) => {
            if (res !== 'error') {
                setSearchResult(res.items)
                setFilteredSearchResult(res.items)
                if (res.length == 0) setNotFound(true)
                setSearchError(false)
                setLoading(false)
                setTotalPages(Math.ceil(res.totalRecords / pageSize));
            } else {
                setSearchError(true)
                setLoading(false)
                setSearchResult([])
                setFilteredSearchResult([])
            }
        })

    }

    const getClientOptions = async () => {
        var clients = await getClients();
        var options = clients.map((entry: any) => ({ value: entry.clientId, text: entry.companyName }));
        setClientOptions(options);
    };

    const columns: TableColumnsType<any> = [
        {
            title: 'Id',
            dataIndex: 'id',
            sorter: true,
            filterOnClose: false,
            render: (value) => {
                return (
                    <a
                        onClick={(event) => handleNavigation(value)}
                        target="_blank"
                    >
                        {value}
                    </a>
                );
            }
        },
        {
            title: 'Complaint Date',
            dataIndex: 'complaintDate',
            render: (text) => dayjs(text).format('MM/DD/YYYY'),
            filterOnClose: false,
            filtered: startDate && endDate,
            onFilterDropdownOpenChange: (visible) => handleDateFilterOpen(visible),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <RangePicker
                    allowClear={true}
                    format={["MM/DD/YYYY"]}
                    onChange={(dates: any) => {
                        if (dates) {
                            setTempStartDate(dates[0]);
                            setTempEndDate(dates[1]);
                        }
                        else {
                            setTempStartDate('');
                            setTempEndDate('');
                        }
                    }}
                    value={[tempStartDate, tempEndDate]}
                />
                <Flex justify='flex-end' style={{ marginTop: 8 }}>

                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: true });
                            setCurrentPage(1);
                            onDateFilterChanged('complaintDate');
                        }}
                    >
                        OK
                    </Button>

                </Flex>
            </div>,
            sorter: true
        },
        {
            title: 'Client',
            dataIndex: 'clientName',
            filters: clientOptions,
            filterOnClose: false,
            filteredValue: clientFilter || null,
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'statusName',
            sorter: true,
            filterOnClose: false,
            filteredValue: statusFilter || null,
            filters: [
                { value: 1, text: "Open" },
                { value: 7, text: "Pending" },
                { value: 8, text: "In Progress" },
                { value: 9, text: "Internal Review" },
                { value: 10, text: "Client Review" },
                { value: 11, text: "Resolved" },
                { value: 12, text: "Client Denied" },
            ],
        },
        {
            title: 'Due Date',
            dataIndex: 'dueDate',
            render: (text) => (text ? dayjs(text).format('MM/DD/YYYY') : ''),
            filterOnClose: false,
            filtered: dueStart && dueEnd,
            onFilterDropdownOpenChange: (visible) => handleDueDateFilterOpen(visible),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <RangePicker
                    allowClear={true}
                    format={["MM/DD/YYYY"]}
                    onChange={(dates: any) => {
                        if (dates) {
                            setTempDueStart(dates[0]);
                            setTempDueEnd(dates[1]);
                        }
                        else {
                            setTempDueStart('');
                            setTempDueEnd('');
                        }
                    }}
                    value={[tempDueStart, tempDueEnd]}
                />
                <Flex justify='flex-end' style={{ marginTop: 8 }}>

                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: true });
                            setCurrentPage(1);
                            onDateFilterChanged('dueDate');
                        }}
                    >
                        OK
                    </Button>

                </Flex>
            </div>,
            sorter: true
        },
        {
            title: 'Type',
            dataIndex: 'typeName',
            sorter: true,
            filterOnClose: false,
            filteredValue: typeFilter || null,
            filters: [
                { value: 1, text: "Internal Inquiry" },
                { value: 2, text: "Standard" },
                { value: 3, text: "DMHC" },
                { value: 4, text: "Dissatisfaction" },
                { value: 5, text: "Urgent" },
                { value: 6, text: "Accident/Injury/Incident" },
                { value: 7, text: "Crisis Alert" }
            ]
        },
        {
            title: 'Assigned To',
            dataIndex: 'assigneeFullName',
            filters: assignedOptions,
            filterOnClose: false,
            filteredValue: assignedFilter || null,
            sorter: true
        },
        {
            title: 'Member',
            dataIndex: 'memberFullName',
            hidden: true,
            sorter: true
        },
        {
            title: "Case Number",
            dataIndex: "caseNumber",
            filterOnClose: false,
            sorter: true
        },
        {
            title: 'Created On',
            dataIndex: 'createdDate',
            render: (text) => (text ? dayjs(text).format('MM/DD/YYYY') : ''),
            filterOnClose: false,
            filtered: createdAtStart && createdAtEnd,
            onFilterDropdownOpenChange: (visible) => handleCreatedDateFilterOpen(visible),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <RangePicker
                    allowClear={true}
                    format={["MM/DD/YYYY"]}
                    onChange={(dates: any) => {
                        if (dates) {
                            setTempCreatedStart(dates[0]);
                            setTempCreatedEnd(dates[1]);
                        }
                        else {
                            setTempCreatedStart('');
                            setTempCreatedEnd('');
                        }
                    }}
                    value={[tempCreatedStart, tempCreatedEnd]}
                />
                <Flex justify='flex-end' style={{ marginTop: 8 }}>

                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: true });
                            setCurrentPage(1);
                            onDateFilterChanged("createdAt");
                        }}
                    >
                        OK
                    </Button>

                </Flex>
            </div>,
            sorter: true
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            sorter: true
        },
        {
            title: "Trip/s",
            dataIndex: 'tripInfo',
            hidden: true,
        }
    ];

    const [isChecked, setChecked] = useState<CheckedProps[]>(
        columns.map((entry: any) => ({ item: entry.title, checked: !entry.hidden }))
    );

    const newColumns = columns.map((item) => ({
        ...item,
        hidden: !isChecked.find(x => x.item === item.title)?.checked,
    }));

    useEffect(() => {
        if (dateUpdated && startDate == tempStartDate && endDate == tempEndDate && dueEnd == tempDueEnd && dueStart == tempDueStart && createdAtEnd == tempCreatedEnd && createdAtStart == tempCreatedStart) {
            getNextPage(1);
        }
    }, [startDate, endDate, dueStart, dueEnd, createdAtEnd, createdAtStart, dateUpdated]);

    const onDateFilterChanged = (filterType: string) => {
        setDateUpdated(true);
        if (filterType == 'dueDate') {
            setDueStart(tempDueStart);
            setDueEnd(tempDueEnd);
        } else if (filterType == 'createdAt') {
            setCreatedAtEnd(tempCreatedEnd);
            setCreatedAtStart(tempCreatedStart);
        } else if (filterType == 'complaintDate') {
            setStartDate(tempStartDate);
            setEndDate(tempEndDate);
        }
    }

    const handleDateFilterOpen = (visible: boolean) => {
        if (visible) {
            setTempStartDate(startDate);
            setTempEndDate(endDate);
        }
    }

    const handleDueDateFilterOpen = (visible: boolean) => {
        if (visible) {
            setTempDueStart(dueStart);
            setTempDueEnd(dueEnd);
        }
    }

    const handleCreatedDateFilterOpen = (visible: boolean) => {
        if (visible) {
            setTempCreatedStart(createdAtStart);
            setTempCreatedEnd(createdAtEnd);
        }
    }

    const handleTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        setCurrentPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);
        setTotalPages(pagination.total ?? 100);
        setStatusFilter(filters.statusName);
        setClientFilter(filters.clientName);
        setAssignedFilter(filters.assigneeFullName);
        setTypeFilter(filters.typeName);
        setSortedInfo(sorter as SorterResult<any>);
        console.log(sorter)
    };

    function ifToShowResetFilter(): boolean {

        if (startDate == "" && endDate == "" && typeFilter == null && statusFilter == null && assignedFilter == null && aganistTypeFilter == 0 && sourceTypeFilter == 0) {
            return false
        }

        return true
    }

    const employeeMenu: MenuItemType[] = assignedOptions.map((entry: ColumnFilterItem) => ({ label: entry.text as string, key: entry.value as Key }));
    const handleAssignClick: MenuProps['onClick'] = (e) => {
        var option = assignedOptions.find(x => x.value == e.key);
        if (selectedEmployee != option) {
            setSelectedEmployee(option);
            setAssignedToText(`Assign To: ${option?.text}`);
        } else {
            setSelectedEmployee(null);
            setAssignedToText(`Assign To`);
        }
    };
    const assignMenuProps = {
        items: employeeMenu,
        onClick: handleAssignClick,
        selectable: true
    };

    const handleEmployeeClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (selectedEmployee && selectedRowKeys.length > 0)
            showConfirmAssign(selectedEmployee?.text as string);
    };

    const statusMenu: MenuItemType[] = [{ key: 1, label: "Open" },
    { key: 7, label: "Pending" },
    { key: 8, label: "In Progress" },
    { key: 9, label: "Internal Review" },
    { key: 10, label: "Client Review" },
    { key: 11, label: "Resolved" },
    { key: 12, label: "Client Denied" }
    ];
    const handleChangeStatusClick: MenuProps['onClick'] = (e) => {
        var option = statusMenu.find(x => x?.key?.toString() == e.key);
        console.log(option)
        console.log(e)
        console.log(selectedStatus)
        if (selectedStatus?.label != option?.label) {
            setSelectedStatus(option);
            setChangeStatusText(`Change Status: ${option?.label}`);
        } else {
            setSelectedStatus(null);
            setChangeStatusText(`Change Status`);
        }
    };
    

    const handleStatusClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (selectedStatus && selectedRowKeys.length > 0)
            showConfirmChangeStatus(selectedStatus?.label as string);
    };

    const showConfirmChangeStatus = (name: string) => {
        confirm({
            title: `Are you sure you want to change the status of these ${selectedRowKeys.length} complaints to ${name}?`,
            icon: <ExclamationCircleFilled />,
            onOk() {
                console.log('OK');
                updateMultiStatus();
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    };

    async function updateMultiStatus() {
        var res = await multi({
            ids: selectedRowKeys,
            field: "ComplaintStatusId",
            value: selectedStatus?.key.toString()
        }, (res : boolean) => {
            console.log(res)
            if (res)
                openNotificationWithIcon("success", "Multiple complaints updated successfully", "");
            getNextPage(currentPage);
        });
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
        console.log('selectedRowKeys changed: ', selectedRows);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const showConfirmAssign = (name: string) => {
        confirm({
            title: `Are you sure you want to assign these ${selectedRowKeys.length} complaints to ${name}?`,
            icon: <ExclamationCircleFilled />,
            onOk() {
                console.log('OK');
                updateMultiAssignedTo();
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    };

    async function updateMultiAssignedTo() {
        var res = await multi({
            ids: selectedRowKeys,
            field: "AssignedTo",
            value: selectedEmployee?.value.toString()
        }, (res : boolean) => {
            console.log(res)
            if (res)
                openNotificationWithIcon("success", "Multiple complaints updated successfully", "");
            getNextPage(currentPage);
        });
    }

    return <ConfigProvider theme={{
        components: {
            Layout: { bodyBg: "#eff1f5", siderBg: "#fff" }
        }
    }} >
        {contextHolder}
        <Layout style={{ borderWidth: 0, borderTopWidth: 1, borderColor: "#d9d9d9", borderStyle: "solid", height: "100%" }}>
            <Layout style={{ minHeight: "calc(100vh - 165px)", padding: "2rem" }}>
                <Content
                    ref={listRef}
                >
                    <Flex justify='end' style={{ marginBottom: "2rem" }} gap='small'>
                        <Input
                            onChange={handleSearch}
                            style={{ width: "35vw" }}
                            // size={"large"}
                            allowClear
                            addonBefore={
                                <Select
                                    style={{ width: "120px" }}
                                    options={searchTypeOptions}
                                    onSelect={onChangeSearchType}
                                    value={searchTypeValue} />}
                        />
                        <Space>
                            { showAssignTo && 
                                <Dropdown.Button menu={assignMenuProps} onClick={handleEmployeeClick} disabled={!selectedRowKeys.length} icon={<DownOutlined />}>
                                    {assignedToText}
                                </Dropdown.Button>
                            }
                            <Dropdown.Button menu={{
                                items: statusItems,
                                onClick: handleChangeStatusClick,
                                selectable: true
                            }}
                            onClick={handleStatusClick} disabled={!selectedRowKeys.length} icon={<DownOutlined />}>
                                {changeStatusText}
                            </Dropdown.Button>
                            <Button onClick={debouncedExport} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', marginLeft: '5px', color: 'rgba(0, 0, 0, 0.88)' }} type="primary">
                                Export
                            </Button>
                        </Space>
                    </Flex>
                    <ColumnDropdown columns={columns} menuVisible={menuVisible} position={position} isChecked={isChecked} setChecked={setChecked} />
                    <Table 
                        style={{ overflowY: "auto", height: "calc(100vh - 164px)" }}
                        dataSource={filteredSearchResult}
                        pagination={{
                            defaultCurrent: 1,
                            current: currentPage,
                            pageSize: pageSize,
                            total: totalPages,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            showSizeChanger: true,
                        }}
                        columns={newColumns}
                        onChange={handleTableChange}
                        rowSelection={rowSelection}
                        rowKey="id"
                        onHeaderRow={(columns, index) => {
                            return {
                                onContextMenu: (event) => {
                                    event.preventDefault();
                                    if (!menuVisible) {
                                        document.addEventListener(`click`, function onClickOutside() {
                                            setMenuVisible(false);
                                            document.removeEventListener(`click`, onClickOutside);
                                        });
                                    }
                                    setMenuVisible(true);
                                    setPosition({ x: event.clientX, y: event.clientY });
                                }
                            };
                        }}
                    />

                </Content>

            </Layout>
        </Layout>
    </ConfigProvider>
}