import { TableColumnsType } from "antd";
import dayjs from "dayjs";

export const AuditTableColumns: TableColumnsType<any> = [
    {
        title: 'Date/Time',
        dataIndex: 'date',
        render: (text) => dayjs(text).format('MM/DD/YYYY HH:mm A'),
        sorter: true
    },
    {
        title: 'User Id',
        dataIndex: 'userName',
        sorter: true
    },
    {
        title: 'Field',
        dataIndex: 'fieldName',
        sorter: true
    },
    {
        title: 'Original Value',
        dataIndex: 'oldValue'
    },
    {
        title: 'New value',
        dataIndex: 'newValue'
    },
    {
        title: 'Note Text',
        dataIndex: 'note'
    }
];

export const DownloadFileColumns: TableColumnsType<any> = [
    {
        title: 'Date/Time',
        dataIndex: 'date',
        render: (text) => dayjs(text).format('MM/DD/YYYY HH:mm A'),
        sorter: true
    },
    {
        title: 'System User',
        dataIndex: 'userName',
        sorter: true
    },
    {
        title: 'File Name',
        dataIndex: 'fileName',
        sorter: true
    }
];

export const UploadFileColumns: TableColumnsType<any> = [
    {
        title: 'Date/Time',
        dataIndex: 'date',
        render: (text) => dayjs(text).format('MM/DD/YYYY HH:mm A'),
        sorter: true
    },
    {
        title: 'System User',
        dataIndex: 'userName',
        sorter: true
    },
    {
        title: 'File Name',
        dataIndex: 'fileName',
        sorter: true
    },
    {
        title: 'Note',
        dataIndex: 'note'
    }
];

export const DownloadDataColumns: TableColumnsType<any> = [
    {
        title: 'Date/Time',
        dataIndex: 'date',
        render: (text) => dayjs(text).format('MM/DD/YYYY HH:mm A'),
        sorter: true
    },
    {
        title: 'System User',
        dataIndex: 'userName',
        sorter: true
    },
    {
        title: 'File Name',
        dataIndex: 'fileName',
        sorter: true
    },
    {
        title: 'Status',
        dataIndex: 'status',
        sorter: true
    }
];