import https, { https_ } from './httpsService'
import * as api from './api'
import { checkToken } from '../helpers/commonHelpers';
import { ClientReview } from '../types';
let v1 = '/api/v1'

export async function getGrievances() {

    let res = await https.get("/api/v1/grievances")
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.log(err)
        });
    return res;
}

export async function createGrievanceCall(formData: any, type: string, cb: any, id?: any) {

    let request: any = type == 'POST' ? https_.post : https_.put
    try {
        const url = type == "POST" ? `api/v1/grievances` : `api/v1/grievances`;
        let res = await request(`${url}`, formData, { timeout: 50000 })
        cb(res)
    }
    catch (err) {
        if (err?.response?.data && err?.response?.data?.messages.length) {
            cb({ hasError: true, error: err?.response?.data?.messages[0].description ?? "Please Add Required Fields" })
        }
        else {
            cb({ hasError: true, error: null })
        }
    }

}

export async function search(data: any, cb: any) {

    let res = await https.post(v1 + api.GRIEVANCES_SEARCH, data, { timeout: 50000 })
        // let res = await https.post('https://ccerrtsnet6api-dev.azurewebsites.net/api/v1/grievances/search',data)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb('error')
        });
}

export async function complaintSearch(data: any, cb: any) {

    let res = await https.post(v1 + api.COMPLAINT_SEARCH, data, { timeout: 50000 })
        // let res = await https.post('https://ccerrtsnet6api-dev.azurewebsites.net/api/v1/grievances/search',data)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb('error')
        });
}

export function exportAllComplaint(data: any, cb: any) {

    let res = https.post(v1 + api.EMPLOYEES_GetExportList, data, { timeout: 5000000 });
}

export async function getEmployees(searchText: string) {
    let res = await https.post(v1 + api.EMPLOYEES_SEARCH, { searchText: searchText, pageIndex: 0, pageSize: 100, designationIds: [2001, 2002], considerAll: false })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.log(err)
        });
    return res;
}
export async function getAganistType() {
    let res = await https.get('api/v1/Grievances/GetAgainstType')
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.log(err)
        });
    return res;
}

export async function getSourceType() {
    let res = await https.get('api/v1/Grievances/GetSourceType')
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.log(err)
        });
    return res;
}

export async function getComplaintStatus() {
    let res = await https.get('api/v1/Complaints/GetComplaintStatuses')
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.log(err)
        });
    return res;
}
export async function clientReview(data: ClientReview, cb: (arg: string) => void) {
    https.put(v1 + api.CLIENT_REVIEW, data)
        .then((res) => cb('success'))
        .catch((err) => cb('error'))
        .finally(() => cb('complete'))

}

export async function getClients() {

    let res = await https.get(v1 + api.CLIENTS)
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.log(err)
        });
    return res;
}

export async function multi(data: any, cb: any) {
    let res = await https.post(v1 + api.COMPLAINT_MULTI, data, { timeout: 300000 })
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb('error')
        });
    return res;
}

export async function getAllGrievance(data: any, cb: any) {
    let res = await https.post('api/v1/grievances/GetAllGrievance', data, { timeout: 40000 })
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb('error')
        });
    return res;
}

export async function canUpdateAssignTo(cb: any) {
    let res = await https.post('api/v1/Employees/CanUpdateAssignTo')
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb('error')
        });
    return res;
}

export async function canResolveComplaint(cb: any) {
    let res = await https.post('api/v1/Employees/CanResolveComplaint')
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb('error')
        });
    return res;
}

export async function getInvoiceFilters(cb: any) {
    let res = await https.get('api/v1/ClientInvoices/GetInvoiceListFilters')
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({
                hasError: true,
                error: err
            })
        });
    return res;
}
export async function getInvoices(data: any, cb: any) {
    let res = await https.post('api/v1/ClientInvoices/GetInvoices', data, { timeout: 50000 })
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb('error')
        });
    return res;
}
export async function saveInvoice(data: any, cb: any) {
    let res = await https.post('api/v1/ClientInvoices/SaveInvoice', data)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({
                hasError: true,
                error: err?.response?.data ?? null
            })
        });
    return res;
}

export async function getInvoiceActions(id: any, cb: any) {
    let res = await https.get('api/v1/ClientInvoices/GetInvoiceActions'+id)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({hasError: true, error: err})
        });
    return res;
}
export async function getAddInvoiceDetailsByType(data: any, cb: any) {
    let res = await https.get('api/v1/ClientInvoices/GetAddInvoiceDetailsByType', { params: data })
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({ hasError: true, error: err })
        });
    return res;
}

export async function getInvoiceAuditHistory(data: any, cb: any) {
    let res = await https.post('api/v1/ClientInvoices/GetInvoiceAuditHistory', data)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({
                hasError: true,
                error: err?.response?.data ?? null
            })
        });
    return res;
}
export async function getInvoice(id: any, cb: any) {
    let res = await https.get('api/v1/ClientInvoices/GetInvoice/' + id)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({ hasError: true, error: err?.response?.data ?? null })
        });
    return res;
}

export async function deleteInvoice(id: any, cb: any) {
    let res = await https.delete('api/v1/ClientInvoices/Delete/'+id)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({ hasError: true, error: err?.response?.data ?? null })
        });
    return res;
}

export async function getStatusById(id: any, cb: any) {
    let res = await https.get('api/v1/ClientInvoices/ChangeStatus/' + id)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({ hasError: true, error: err?.response?.data ?? null })
        });
    return res;
}
export async function changeInvoiceStatus(data: any, cb: any) {
    let res = await https.post('api/v1/ClientInvoices/ChangeStatus', data)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({
                hasError: true,
                error: err?.response?.data ?? null
            })
        });
    return res;
}

export async function getUploadedFiles(data: any, cb: any) {
    let res = await https.post('api/v1/ClientInvoices/GetUploadedFiles', data)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({
                hasError: true,
                error: err?.response?.data ?? null
            })
        });
    return res;
}

export async function downloadUploadedFile(id: any, cb: any) {
    let res = await https.get('api/v1/ClientInvoices/DownloadUploadedFile/' + id, {
        responseType: "blob", // Important to receive the response as a Blob
        timeout: 120000
    })
        .then((response) => {
            return cb(response)
        })
        .catch((err) => {
            const errorBlob = err?.response?.data;
            errorBlob.text().then((text) => {
                try {
                    const json = JSON.parse(text); // Parse text to JSON

                    cb({ hasError: true, error: json ?? null })
                } catch (parseError) {
            cb({ hasError: true, error: err?.response?.data ?? null })
                }
        });
        });
    return res;
}
export async function downloadRawDataFile(id: any, cb: any) {
    let res = await https.get('api/v1/ClientInvoices/DownloadRawDataFile/' + id, {
        responseType: "blob", // Important to receive the response as a Blob
        timeout: 120000
    })
        .then((response) => {
            return cb(response)
        })
        .catch((err) => {
            const errorBlob = err?.response?.data;
            errorBlob.text().then((text) => {
                try {
                    const json = JSON.parse(text); // Parse text to JSON

                    cb({ hasError: true, error: json ?? null })
                } catch (parseError) {
            cb({ hasError: true, error: err?.response?.data ?? null })
                }
        });
        });
    return res;
}

export async function generateRawDataFile(id: any, cb: any) {
    let res = await https.post('api/v1/ClientInvoices/GenerateRawDataFile/' + id, null)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({ hasError: true, error: err?.response?.data ?? null })
        });
    return res;
}

export async function uploadFile(data: any, cb: any) {
    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("files", data.file);
    formData.append("note", data.note);
    let res = await https.post('api/v1/ClientInvoices/UploadFile', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        timeout: 50000
    })
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({
                hasError: true,
                error: err?.response?.data ?? null
            })
        });
    return res;
}

export async function getRawDataFiles(data: any, cb: any) {
    let res = await https.post('api/v1/ClientInvoices/GetRawDataFiles', data)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({
                hasError: true,
                error: err?.response?.data ?? null
            })
        });
    return res;
}

export async function getSubmittedFiles(data: any, cb: any) {
    let res = await https.post('api/v1/ClientInvoices/GetSubmittedFiles', data)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({
                hasError: true,
                error: err?.response?.data ?? null
            })
        });
    return res;
}
export async function downloadSubmittedFile(id: any, cb: any) {
    let res = await https.get('api/v1/ClientInvoices/DownloadSubmittedFile/' + id, {
        timeout: 50000,
        responseType: "blob", // Important to receive the response as a Blob
    })
        .then((response) => {
            return cb(response)
        })
        .catch((err) => {
            const errorBlob = err?.response?.data;
            errorBlob.text().then((text) => {
                try {
                    const json = JSON.parse(text); // Parse text to JSON
                    
                    cb({ hasError: true, error: json ?? null })
                } catch (parseError) {
            cb({ hasError: true, error: err?.response?.data ?? null })
                }
            });
        });
    return res;
}

export async function getCorrectionTrips(data: any, cb: any) {
    let res = await https.post('api/v1/ClientInvoices/GetCorrectionTrips', data, {
        timeout: 60000
    })
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({
                hasError: true,
                error: err?.response?.data ?? null
            })
        });
    return res;
}

export async function getCorrectionTriplegDetails(data: any, cb: any) {
    let res = await https.get('api/v1/ClientInvoices/CorrectionTriplegDetails', {
        params: data,
        timeout:50000
    })
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({ hasError: true, error: err?.response?.data ?? null })
        });
    return res;
}

export async function getCorrectionLookups(id: any, cb: any) {
    let res = await https.get('api/v1/ClientInvoices/GetCorrectionLookups/' + id)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({ hasError: true, error: err?.response?.data ?? null })
        });
    return res;
}

export async function saveCorrectionTripLeg(data: any, cb: any) {
    let res = await https.post('api/v1/ClientInvoices/CorrectionTripLegDetails', data, {
        timeout:120000
    })
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({
                hasError: true,
                error: err?.response?.data ?? null
            })
        });
    return res;
}

export async function getReimbursementSearchTypes(cb: any) {
    let res = await https.get('api/v1/Reimbursement/GetSearchTypes')
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({ hasError: true, error: err?.response?.data ?? null })
        });
    return res;
}

export async function getReimbursementList(data: any, cb: any) {
    let res = await https.post('api/v1/Reimbursement/GetReimbursementList', data)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({
                hasError: true,
                error: err?.response?.data ?? null
            })
        });
    return res;
}

export async function getReimbursementDetailById(id:any, cb: any) {
    let res = await https.get('api/v1/Reimbursement/GetReimbursementDetailById?ReimbursementId='+id)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({ hasError: true, error: err?.response?.data ?? null })
        });
    return res;
}

export async function updateReimbursement(data: any, cb: any) {
    let res = await https.post('api/v1/Reimbursement/UpdateReimbursement', data)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({
                hasError: true,
                error: err?.response?.data ?? null
            })
        });
    return res;
}

export async function getTripComplaints(id: any, cb: any) {
    let res = await https.get('api/v1/complaints?tripId=' + id)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            cb({ hasError: true, error: err?.response?.data ?? null })
        });
    return res;
}