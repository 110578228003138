import { Button, Col, DatePicker, Divider, Flex, Input, InputNumber, Row, Select, Space } from "antd";
import Card from "antd/es/card/Card";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getReimbursementDetailById, getTripComplaints, updateReimbursement } from "../../service/httpsCalls";
const { Option } = Select;
export default function EditReimbursement({ accessLevel, selectedRec, handleCallback }) {
    const [form, setForm] = useState<any>();
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkInfoPresent, setCheckInfo] = useState(false);
    const [complaints, setComplaints] = useState([]);
    useEffect(() => {
        if (selectedRec) {
            setLoading(true);
            getReimbursementDetailById(selectedRec.id, (res) => {
                if (res && !res.hasError) {
                    getTripComplaints(res.tripId, (r) => {
                        setLoading(false);
                        let attachToGrievance = '-';
                        if (r && !r.hasError) {
                            if (r?.length) {
                                let complaint: any = [];
                                r.forEach((x) => {
                                    if (x.complaintReasonId.filter(c => c == 25).length == 0) {
                                        complaint.push(x);
                                    }
                                    if (x.complaintInfoId == res?.complaintInfoId) {
                                        attachToGrievance = `Complaint ${res?.complaintInfoId} - Submitted on ${x.complaintDate}`
                                    }
                                })
                                setComplaints(complaint);
                            }
                        }
                        if (res.checkDate || res.checkMail) {
                            setCheckInfo(true);
                        }
                        setForm({
                            ...res,
                            attachToGrievance: attachToGrievance,
                            checkDate: res.checkDate ? dayjs(res.checkDate) : null,
                            checkClearDate: res.checkClearDate ? dayjs(res.checkClearDate) : null,
                            checkMailDate: res.checkMailDate ? dayjs(res.checkMailDate) : null
                        });
                    })
                } else {
                    setLoading(false);
                }
            })
        }
    }, []);

    const save = () => {
        setSubmitted(true);
        if ((accessLevel == 2 || accessLevel == 3) && !(form.checkDate && form.checkMailDate && form.checkAmount && form.checkNumber)) {
            return;
        }
        if ((accessLevel == 1 || accessLevel == 3) && !(form.requestedAmount && form.mailingAddress)) {
            return;
        }

        updateReimbursement({
            "reimbursementId": form.reimbursementId,
            "checkDate": form.checkDate ? dayjs(form.checkDate).format('YYYY-MM-DD'):null,
            "checkAmount": form.checkAmount,
            "checkNumber": form.checkNumber,
            "checkMailDate": form.checkMailDate ? dayjs(form.checkMailDate).format('YYYY-MM-DD') : null,
            "mailingAddress": form.mailingAddress,
            "complaintInfoId": form.complaintInfoId,
            "requestedAmount": form.requestedAmount,
            "checkClearDate": form.checkClearDate ? dayjs(form.checkClearDate).format('YYYY-MM-DD') : null
        }, (res) => {
            if (res?.hasError) {
                const errorMsg = res?.error?.messages?.length > 0 ? res.error?.messages[0].description : 'Something went wrong!!!'
                handleCallback({
                    isInvalid: true,
                    errorMessage: errorMsg
                })
            } else {
                setSubmitted(false);
                handleCallback({
                    success: true,
                    message: 'Reimbursement updated successfully!'
                });
            }
        })
    }

    const handleAmountChange = (inputValue, field) => {
        // Check if inputValue is valid (null if cleared)
        if (inputValue === null) {
            if (field == 'checkAmount') {
                setForm({
                    ...form,
                    checkAmount: null
                })
            } else if (field == 'requestedAmount') {
                setForm({
                    ...form,
                    requestedAmount: null
                })
            }
            
            return;
        }
        var valueString = inputValue.toString();
        var decimalIndex = valueString.indexOf('.');
        if (decimalIndex != -1) {
            valueString = valueString.length > 9 ? valueString.slice(0, 9) : valueString;
            if (valueString.length - decimalIndex > 2) {
                valueString = parseFloat(valueString.slice(0, (decimalIndex + 3)))
            } else {
                valueString = parseFloat(valueString);
            }
            if (field == 'checkAmount') {
                setForm({
                    ...form,
                    checkAmount: valueString
                })
            } else if (field == 'requestedAmount') {
                setForm({
                    ...form,
                    requestedAmount: valueString
                })
            }
        } else {
            if (field == 'checkAmount') {
                setForm({
                    ...form,
                    checkAmount: inputValue
                })
            } else if (field == 'requestedAmount') {
                setForm({
                    ...form,
                    requestedAmount: inputValue
                })
            }
        }

    };
    return (
        <div style={{ paddingTop: '15px' }}>
            <Card loading={loading}>
                <Row type="flex" style={{ paddingBottom: '1rem', background: '#fff' }}>
                    <Col span={8} style={{ paddingRight: '0.5rem' }}>
                        <label>CTC Trip Id</label>
                        <br />
                        {form?.ctcTripId}
                    </Col>
                    <Col span={8} style={{ paddingRight: '0.5rem' }}>
                        <label>Date of Service</label>
                        <br />
                        {form?.dateOfService ? dayjs(form?.dateOfService).format('MM/DD/YYYY') : ''}
                    </Col>
                    <Col span={8}>
                        <label>Member Id</label>
                        <br />
                        {form?.memberId}
                    </Col>
                </Row>
                {
                    (accessLevel == 2 || checkInfoPresent )?
                        <Row type="flex" style={{ paddingBottom: '1rem', background: '#fff' }}>
                            <Col span={8}>
                                <label>Requested Amount</label>
                                <br />
                                {form?.requestedAmount == null || isNaN(form?.requestedAmount) ? '-' : new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                }).format(form?.requestedAmount)}
                            </Col>
                            <Col span={8} style={{ paddingRight: '0.5rem' }}>
                                <label>Mailing Address</label>
                                <br />
                                {form?.mailingAddress}
                            </Col>
                            <Col span={8} style={{ paddingRight: '0.5rem' }}>
                                <label>Attach to grievance</label>
                                <br />
                                {form?.attachToGrievance}
                            </Col>
                        </Row>
                        :
                        <Row type="flex" style={{ paddingBottom: '1rem', background: '#fff' }}>
                            <Col span={8}>
                                <label>Requested Amount</label>
                                <InputNumber<string> prefix="$" value={form?.requestedAmount} maxLength={9} status={submitted && !form?.requestedAmount ? 'error' : ''} placeholder="Requested Amount" style={{ width: '90%' }}
                                    step="0.01"
                                    max="999999.99" // Maximum value allowed
                                    min="0"  // Minimum value allowed
                                    onChange={(e) => handleAmountChange(e, 'requestedAmount')}
                                />
                            </Col>
                            <Col span={8} style={{ paddingRight: '0.5rem' }}>
                                <label>Mailing Address</label>
                                <Input value={form?.mailingAddress} status={submitted && !form?.mailingAddress ? 'error' : ''} placeholder="Mailing Address" style={{ width: '90%' }} allowClear
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            mailingAddress: e?.target?.value
                                        })
                                    }}
                                />
                            </Col>
                            <Col span={8} style={{ paddingRight: '0.5rem' }}>
                                <label>Attach to grievance</label>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select a grievance"
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            complaintInfoId: e?.target?.value
                                        })
                                    }}
                                    value={form?.complaintInfoId}
                                >
                                    {complaints.length && complaints.map((item:any) => (
                                        <Option key={item.complaintInfoId}>Complaint {item.complaintInfoId} - Submitted on {item.complaintDate}</Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                }
                {
                    accessLevel == 1 && 
                    <>
                        <Row type="flex" style={{ paddingBottom: '1rem', background: '#fff' }}>
                            <Col span={8} style={{ paddingRight: '0.5rem' }}>
                                <label>Check Date</label>
                                <br />
                                {form?.checkDate ? dayjs(form?.checkDate).format('MM/DD/YYYY') : '-'}
                            </Col>
                            <Col span={8} style={{ paddingRight: '0.5rem' }}>
                                <label>Check Clear Date</label>
                                <br />
                                {form?.checkClearDate ? dayjs(form?.checkClearDate).format('MM/DD/YYYY') : '-'}
                            </Col>
                            <Col span={8}>
                                <label>Check Mail Date</label>
                                <br />
                                {form?.checkMailDate ? dayjs(form?.checkMailDate).format('MM/DD/YYYY') : '-'}
                            </Col>
                        </Row>
                        <Row type="flex" style={{ paddingBottom: '1rem', background: '#fff' }}>
                            <Col span={8} style={{ paddingRight: '0.5rem' }}>
                                <label>Check Amount</label>
                                <br />
                                {form?.checkAmount == null || isNaN(form?.checkAmount) ? '-' : new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                }).format(form?.checkAmount)}
                            </Col>
                            <Col span={8} style={{ paddingRight: '0.5rem' }}>
                                <label>Check Number</label>
                                <br />
                                {form?.checkNumber ?? '-'}
                            </Col>
                        </Row>
                    </>
                }
                {
                    (accessLevel == 3 || accessLevel == 2) &&
                    <>
                        <Row type="flex" style={{ paddingBottom: '1rem', background: '#fff' }}>
                            <Col span={8} style={{ paddingRight: '0.5rem' }}>
                                <label>Check Date</label>
                                <DatePicker placeholder='Check Date' status={submitted && !form?.checkDate ? 'error' : ''} value={form?.checkDate} format="MM/DD/YYYY" style={{ width: '100%' }}
                                    onChange={(date: any) => {
                                        setForm({
                                            ...form,
                                            checkDate: date ? date : ''
                                        })
                                    }}
                                />
                            </Col>
                            <Col span={8} style={{ paddingRight: '0.5rem' }}>
                                <label>Check Clear Date</label>
                                <DatePicker placeholder='Check Clear Date' value={form?.checkClearDate} format="MM/DD/YYYY" style={{ width: '100%' }}
                                    onChange={(date: any) => {
                                        setForm({
                                            ...form,
                                            checkClearDate: date ? date : ''
                                        })
                                    }}
                                />
                            </Col>
                            <Col span={8}>
                                <label>Check Mail Date</label>
                                <DatePicker placeholder='Check Mail Date' status={submitted && !form?.checkMailDate ? 'error' : ''} value={form?.checkMailDate} format="MM/DD/YYYY" style={{ width: '100%' }}
                                    onChange={(date: any) => {
                                        setForm({
                                            ...form,
                                            checkMailDate: date ? date : ''
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row type="flex" style={{ paddingBottom: '1rem', background: '#fff' }}>
                            <Col span={8} style={{ paddingRight: '0.5rem' }}>
                                <label>Check Amount</label>
                                <InputNumber<string> prefix="$" value={form?.checkAmount} maxLength={9} status={submitted && !form?.checkAmount ? 'error' : ''} placeholder="Check Amount" style={{ width: '100%' }}
                                    step="0.01"
                                    max="999999.99" // Maximum value allowed
                                    min="0"  // Minimum value allowed
                                    onChange={(e) => handleAmountChange(e, 'checkAmount')}

                                />
                            </Col>
                            <Col span={8} style={{ paddingRight: '0.5rem' }}>
                                <label>Check Number</label>
                                <Input value={form?.checkNumber} status={submitted && !form?.checkNumber ? 'error' : ''} placeholder="Check Number" style={{ width: '100%' }} allowClear
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            checkNumber: e?.target?.value
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                    </>
                }
                <Divider style={{ margin: '10px 0px' }} />
                <Flex justify="end">
                    <Space>
                        {
                            !(accessLevel == 1 && checkInfoPresent) &&
                            <Button key="save" onClick={() => save()} style={{ backgroundColor: "blue", color: "#fff" }}>Save</Button>
                        }
                        <Button key="cancelEdit" onClick={() => handleCallback({ closeDrawer :true})} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.88)' }}>Cancel</Button>
                    </Space>
                </Flex>
            </Card>
        </div>
    )
}