import { Checkbox, Col, ConfigProvider, Layout, Row, notification, DatePicker, Flex, Button, Input, Table } from "antd";
import { getInvoiceFilters, getInvoices } from "../../service/httpsCalls";
import { useEffect, useState } from "react";
import { Select, Divider } from 'antd';
import { PlusOutlined, SearchOutlined, EyeOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import { SorterResult } from "antd/es/table/interface";
import type { MenuProps, TableColumnsType, TableProps } from 'antd';
import { LoaderSpinner } from "../../components/common/Loader";
import { ColumnDropdown } from "../../components/common/ColumnDropdown";
const { Option } = Select;
const { RangePicker } = DatePicker;
interface FilterData {
    id: number,
    name: string
}

interface CheckedProps {
    item: string;
    checked: boolean;
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';
export default function Invoices() {
    const [api, contextHolder] = notification.useNotification();
    const [clientList, setClientList] = useState<FilterData[]>([]);
    const [invoiceTypes, setInvoiceTypes] = useState<FilterData[]>([]);
    const [statusList, setStatusList] = useState<FilterData[]>([]);
    const [invoiceNumber, setInvoiceNumber] = useState<string>();
    const [client, setClient] = useState<any | null>(null);
    const [startDate, setStartDate] = useState<string | any>('');
    const [endDate, setEndDate] = useState<string | any>('');
    const [status, setStatus] = useState<any | null>(null);
    const [invoiceType, setInvoiceType] = useState<any | null>(null);
    const [invoiceList, setInvoiceList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
    const [menuVisible, setMenuVisible] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
        api[type]({
            message: message,
            description: description
        });
    };
    const [position, setPosition] = useState({
        x: 0,
        y: 0
    });

    const columns: TableColumnsType<any> = [
        {
            title: 'Invoice Date',
            dataIndex: 'invoiceDate',
            render: (text) => dayjs(text).format('MM/DD/YYYY'),
            sorter: true
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoiceNumber',
            sorter: true
        },
        {
            title: 'Type',
            dataIndex: 'invoiceType',
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'invoiceStatus',
            sorter: true
        },
        {
            title: 'DOS Range',
            dataIndex: 'dosRange',
            sorter: true
        },
        {
            title: 'Include Previous Non-billable',
            dataIndex: 'includePreviousNonBillable',
            sorter: true
        },
        {
            title: 'View',
            dataIndex: 'invoiceNumber',
            render: (text, record) => (
                <Button onClick={() => console.log(record)} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.88)' }} type="primary">
                    <EyeOutlined/>
                </Button>
            ),
            hidden: true
        },
    ];

    const [isChecked, setChecked] = useState<CheckedProps[]>(
        columns.map((entry: any) => ({ item: entry.title, checked: !entry.hidden }))
    );

    const invoiceColumns = columns.map((item) => ({
        ...item,
        hidden: !isChecked.find(x => x.item === item.title)?.checked,
    }));

    function getFilterData(){
        getInvoiceFilters((res) => {
            if (res) {
                console.log(res);
                setClientList(res.clientList);
                setInvoiceTypes(res.invoiceTypeList);
                setStatusList(res.invoiceStatusList);
            }
        })
    }

    function resetFilters() {
        setInvoiceType(null);
        setStatus(null);
        setClient(null)
        setStartDate('');
        setEndDate('');
        setInvoiceNumber(null);
    }

    function getInvoiceList() {
        setLoading(true);
        setInvoiceList([]);
        getInvoices({
            "pageIndex": currentPage,
            "pageSize": pageSize,
            "sortField": sortedInfo.order ? sortedInfo.field:null,
            "sortDescending": sortedInfo.order == 'descend',
            "clientId": client,
            "fromInvoiceDate": startDate ?? null,
            "toInvoiceDate": endDate ?? null,
            "invoiceNumber": invoiceNumber,
            "invoiceTypeId": invoiceType,
            "invoiceStatusId": status
        }, (res) => {
            if (res !== 'error') {
                !showTable && setShowTable(true);
                setInvoiceList(res.items);
                setLoading(false)
                setTotalPages(Math.ceil(res.totalRecords / (pageSize / pageSize)));
            } else {
                setInvoiceList([]);
                setLoading(false)
                setTotalPages(1);
            }
            
        })
    }
    function search() {
        if (!client) {
            openNotificationWithIcon("warning", "Please Select a Client", "");
            return;
        }
        if (pageSize == 0) {
            setPageSize(10);
        } else {
            getInvoiceList();
        }
    }

    useEffect(() => {
        getFilterData();
    }, []);

    useEffect(() => {
        console.log(currentPage);
        if (pageSize != 0)
            getInvoiceList();
    }, [currentPage, pageSize, sortedInfo]);

    const handleTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        setCurrentPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);
        setTotalPages(pagination.total ?? 100);
        setSortedInfo(sorter as SorterResult<any>);
        console.log(sorter)
    };


    return <ConfigProvider theme={{
        components: {
            Layout: { bodyBg: "#eff1f5", siderBg: "#fff" }
        }
    }} >
        {contextHolder}
        <Layout style={{
            padding: '20px',
            marginBottom: '5px',
            marginTop: '5px',            
        }}>
            <h3 style={{ textTransform: 'uppercase' }}>Invoices</h3>
            <Flex justify='space-between' style={{ padding: "1rem", paddingBottom:"2rem", background: '#fff' }}>
                <label><SearchOutlined />&nbsp;Search</label>
                {/*<Button onClick={resetFilters} style={{ marginLeft: '5px' }} type="primary" icon={<PlusOutlined /> }>*/}
                {/*    Add Invoice*/}
                {/*</Button>*/}
            </Flex>
            <Row type="flex" style={{ padding: '1rem', paddingTop: "0px", background: '#fff' }}>
                <Col span={6}>
                    <Select
                        style={{ width: '90%' }}
                        placeholder="Select a Client"
                        onChange={(e) => setClient(e)}
                        value={client}
                        allowClear
                    >
                        {clientList && clientList.map(item => (
                            <Option key={item.id}>{item.name}</Option>
                        ))}
                    </Select>
                </Col>
                <Col span={6}>
                    <Select
                        style={{ width: '90%' }}
                        placeholder="Select a Type"
                        onChange={(e) => setInvoiceType(e)}
                        value={invoiceType}
                        allowClear
                    >
                        {invoiceTypes && invoiceTypes.map(item => (
                            <Option key={item.id}>{item.name}</Option>
                        ))}
                    </Select>
                </Col>
                <Col span={12}>
                    <RangePicker style={{ width: '100%' }}
                        format="YYYY/MM/DD"
                        value={[startDate, endDate]}
                        onChange={(dates: any) => {
                            if (dates) {
                                setStartDate(dates[0]);
                                setEndDate(dates[1]);
                            } else {
                                setStartDate('');
                                setEndDate('');
                            }
                        }}
                    />
                </Col>
            </Row>
            <Row type="flex" style={{ padding: '1rem', paddingTop:"0px", background: '#fff' }}>
                <Col span={6}>
                    <Input value={invoiceNumber} placeholder="Invoice Number" style={{ width: '90%' }} allowClear onChange={(e) => setInvoiceNumber(e?.target?.value)} />
                </Col>
                <Col span={6}>
                    <Select
                        style={{ width: '90%' }}
                        placeholder="Select a Status"
                        onChange={(e) => setStatus(e)}
                        value={status}
                        allowClear
                    >
                        {statusList && statusList.map(item => (
                            <Option key={item.id}>{item.name}</Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <Flex justify='end' style={{ marginBottom: "2rem", padding: "1rem", paddingTop: "0px", background: '#fff' }} gap='small'>
                <Button onClick={search} style={{ marginLeft: '5px' }} type="primary">
                    Search
                </Button>
                <Button onClick={resetFilters} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', marginLeft: '5px', color: 'rgba(0, 0, 0, 0.88)' }} type="primary">
                    Reset
                </Button>
            </Flex>
            <ColumnDropdown columns={columns} menuVisible={menuVisible} position={position} isChecked={isChecked} setChecked={setChecked} />
            { showTable && <Table
                style={{ overflowY: "auto", height: "calc(100vh - 164px)" }}
                dataSource={invoiceList}
                pagination={{
                    defaultCurrent: 1,
                    current: currentPage,
                    pageSize: pageSize,
                    total: totalPages,
                    showTotal: (total, range) => `${range[0] || 0 }-${range[1] || 1} of ${total} items`,
                    showSizeChanger: true,
                }}
                columns={invoiceColumns}
                onChange={handleTableChange}
                rowKey="invoiceNumber"
                loading={{ indicator: <LoaderSpinner size={24} topMargin="10px" />, spinning: loading }}
                onHeaderRow={(columns, index) => {
                    return {
                        onContextMenu: (event) => {
                            event.preventDefault();
                            if (!menuVisible) {
                                document.addEventListener(`click`, function onClickOutside() {
                                    setMenuVisible(false);
                                    document.removeEventListener(`click`, onClickOutside);
                                });
                            }
                            setMenuVisible(true);
                            setPosition({ x: event.clientX, y: event.clientY });
                        }
                    };
                }}
             /> }
        </Layout>
    </ConfigProvider>
}