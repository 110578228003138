import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, ConfigProvider, DatePicker, Drawer, Flex, Input, Layout, Row, Select, Skeleton, Table, TableColumnsType, notification } from "antd";
import { LoaderSpinner } from "../../components/common/Loader";
import { ColumnDropdown } from "../../components/common/ColumnDropdown";
import { useEffect, useState } from "react";
import { SorterResult } from "antd/es/table/interface";
import { ReimbursementColumns } from "../Billing/tableConfig";
import EditReimbursement from "./editReimbursement";
import { getReimbursementList, getReimbursementSearchTypes } from "../../service/httpsCalls";
const { RangePicker } = DatePicker;
const { Option } = Select;

interface FilterData {
    searchOption: any,
    startDate: string | any,
    endDate: string | any,
    showClearDateRec: boolean
}

interface SearchType {
    id: number,
    name: string
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export default function ReimbursementList() {
    const [api, contextHolder] = notification.useNotification();
    const [searchTypeValue, setSearchTypeValue] = useState();
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({ order: 'descend', field: "dateOfService" });
    const [menuVisible, setMenuVisible] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [reibmursementList, setReimbursementList] = useState([]);
    const [selectedRec, setSelectedRec] = useState<any | null>(null);
    const [filterLoading, setFilterLoading] = useState(false)
    const reibmursementColumns: TableColumnsType<any> = ReimbursementColumns.concat([
        {
            title: 'Edit',
            dataIndex: 'id',
            render: (text, record) =>
                <Button onClick={() => editReimbursement(record)} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.88)' }} type="primary">
                    <EditOutlined />
                </Button>
        }
    ]);
    const [showDrawer, setShowDrawer] = useState<boolean>(false);

    const [position, setPosition] = useState({
        x: 0,
        y: 0
    });
    const [filters, setFilters] = useState<FilterData>({
        searchOption: null,
        startDate: null,
        endDate: null,
        showClearDateRec: false
    })
    const [searchTypeOptions, setSearchTypeOptions] = useState<SearchType[]>([]);
    const [accessLevel, setAccessLevel] = useState(null);
    const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
        api[type]({
            message: message,
            description: description
        });
    };

    const search = () => {
        if (pageSize == 0) {
            setPageSize(10);
        } else {
            currentPage == 1 ? getData() : setCurrentPage(1);
        }
    }

    const getData = () => {
        if (!filters.searchOption || !filters.startDate || !filters.endDate) {
            openNotificationWithIcon("error", "Please select date range!!!", "");
            return;
        }
        setLoading(true);
        getReimbursementList({
            "pageIndex": currentPage,
            "pageSize": pageSize,
            "sortField": sortedInfo.order ? sortedInfo.field : null,
            "sortDescending": sortedInfo.order == 'descend',
            "fromDate": filters.startDate && filters.startDate != '' ? filters.startDate : null,
            "toDate": filters.endDate && filters.endDate != '' ? filters.endDate : null,
            "isCheckClearDate": filters.showClearDateRec,
            "searchTypeId": parseInt(filters.searchOption)
        }, (res) => {
            if (res !== 'error') {
                !showTable && setShowTable(true);
                setReimbursementList(res.items);
                setLoading(false)
                setTotalPages(Math.ceil(res.totalRecords / (pageSize / pageSize)));
            } else {
                setReimbursementList([]);
                setLoading(false)
                setTotalPages(1);
            }
        })
    }
    const resetFilters = () => {
        let searchOpt = searchTypeOptions?.length > 0 ? searchTypeOptions[0].id?.toString() : null;
        setFilters({
            searchOption: searchOpt,
            startDate: null,
            endDate: null,
            showClearDateRec: false
        });
        setReimbursementList([]);
        setTotalPages(1);
    }

    const editReimbursement = (rec) => {
        setSelectedRec(rec);
        setShowDrawer(true);
    }
    function closeDrawer(data) {
        setShowDrawer(false);
        setSelectedRec(null);
    }

    const handleTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        setCurrentPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);
        setTotalPages(pagination.total ?? 100);
        setSortedInfo(sorter as SorterResult<any>);
    };

    function getSearchTypes() {
        setFilterLoading(true);
        getReimbursementSearchTypes((res) => {
            setFilterLoading(false);
            if (res.hasError) {
                openNotificationWithIcon("error", "Something went wrong!!!", "");
                return;
            }
            if (res) {
                setAccessLevel(res.reimbursementAccess);
                if (!res.reimbursementAccess || res.reimbursementAccess == 0) {
                    openNotificationWithIcon("error", "You do not have Reimbursemnt Access to view the details!!!", "Contact administrator or supervisor.");
                    return;
                }
                setSearchTypeOptions(res.searchTypes);
                res.searchTypes?.length > 0 && setFilters({ ...filters, searchOption: res.searchTypes[0].id?.toString() ?? null })
            }
        })
    }

    const handleCallback = (data) => {
        if (data.closeDrawer) {
            closeDrawer(data);
        } else if (data.isInvalid) {
            openNotificationWithIcon("warning", data.errorMessage, "");
        } else if (data.success) {
            openNotificationWithIcon("success", data.message, "");
            closeDrawer(data);
            getData();
        }
    }

    useEffect(() => {
        getSearchTypes();
    }, []);

    useEffect(() => {
        if (pageSize != 0)
            getData();
    }, [currentPage, pageSize, sortedInfo]);

    return <ConfigProvider theme={{
        components: {
            Layout: { bodyBg: "#eff1f5", siderBg: "#fff" }
        }
    }} >
        {contextHolder}
        <Layout style={{
            padding: '20px',
            marginBottom: '5px',
            marginTop: '5px',
        }}>
            <Drawer title="Edit Reimbursement" destroyOnClose={true} maskClosable={false} onClose={closeDrawer} open={showDrawer} width={1000} styles={{ body: { background: '#eff1f5' } }}>
                <div>
                    {
                        showDrawer && selectedRec &&
                        <EditReimbursement accessLevel={accessLevel} selectedRec={selectedRec} handleCallback={handleCallback} />
                    }
                </div>
            </Drawer>
            <h3 style={{ textTransform: 'uppercase', marginTop: '0px' }}>Reimbursement</h3>
            <Flex justify='space-between' style={{ padding: "1rem", alignItems: "center", background: '#fff' }}>
                <label><SearchOutlined />&nbsp;Search</label>
            </Flex>
            <Skeleton loading={filterLoading} active>
                <Flex justify="space-between" style={{ marginBottom: "2rem", padding: "1rem", paddingTop: "0px", background: '#fff', alignItems: "center" }} gap={10} >
                    <div style={{ width:"100%" }}>
                        <Select
                            style={{ width: "150px" }}
                            placeholder="Select"
                            onSelect={x => setFilters({ ...filters, searchOption: x })}
                            value={filters?.searchOption}>
                            {searchTypeOptions && searchTypeOptions.map(item => (
                                <Option key={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                        <RangePicker
                            placeholder={["From Date", "To Date"]}
                            style={{ width: 'calc(100% - 150px)' }}
                            format="MM/DD/YYYY"
                            value={[filters.startDate, filters.endDate]}
                            onChange={(dates: any) => {
                                if (dates) {
                                    setFilters({ ...filters, startDate: dates[0], endDate: dates[1] })
                                } else {
                                    setFilters({ ...filters, startDate: '', endDate: '' })
                                }
                            }}
                        />
                    </div>
                    <Checkbox style={{ width: "100%" }} onChange={(e) => {
                            setFilters({ ...filters, showClearDateRec: e.target.checked })
                        }}>
                        Show records with Check Clear date
                    </Checkbox>
                    <div style={{ width: "100%" }}>
                        <Button onClick={search} loading={loading} disabled={!accessLevel || accessLevel == 0} style={{ marginLeft: '5px' }} type="primary">
                            Search
                        </Button>
                        <Button onClick={resetFilters} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', marginLeft: '5px', color: 'rgba(0, 0, 0, 0.88)' }} type="primary">
                            Reset
                        </Button>
                    </div>
                </Flex>
            </Skeleton>
            {showTable && <Table style={{ background: "#fff" }}
                scroll={{
                    y: reibmursementList?.length > 0 ? "calc(100vh - 400px)" : undefined
                }}
                dataSource={reibmursementList}
                pagination={{
                    defaultCurrent: 1,
                    current: currentPage,
                    pageSize: pageSize,
                    total: totalPages,
                    showTotal: (total, range) => `${range[0] || 0}-${range[1] || 1} of ${total} items`,
                    showSizeChanger: true,
                }}
                columns={reibmursementColumns}
                onChange={handleTableChange}
                size="small"
                rowKey="id"
                loading={{ indicator: <LoaderSpinner size={24} topMargin="10px" />, spinning: loading }}
                onHeaderRow={(columns, index) => {
                    return {
                        onContextMenu: (event) => {
                            event.preventDefault();
                            if (!menuVisible) {
                                document.addEventListener(`click`, function onClickOutside() {
                                    setMenuVisible(false);
                                    document.removeEventListener(`click`, onClickOutside);
                                });
                            }
                            setMenuVisible(true);
                            setPosition({ x: event.clientX, y: event.clientY });
                        }
                    };
                }}
            />}
        </Layout>
    </ConfigProvider>

}