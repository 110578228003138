import { Checkbox, Col, ConfigProvider, Layout, Row, notification, DatePicker, Flex, Button, Input, Table, Modal, Drawer } from "antd";
import { getInvoiceFilters, getInvoices, saveInvoice as save } from "../../service/httpsCalls";
import { useEffect, useState } from "react";
import { Select, Divider } from 'antd';
import { PlusOutlined, SearchOutlined, EyeOutlined } from "@ant-design/icons";
import dayjs, { Dayjs, extend } from "dayjs";
import { SorterResult } from "antd/es/table/interface";
import type { MenuProps, TableColumnsType, TableProps } from 'antd';
import { LoaderSpinner } from "../../components/common/Loader";
import { ColumnDropdown } from "../../components/common/ColumnDropdown";
import CreateInvoice from "./createInvoice";
const { Option } = Select;
const { RangePicker } = DatePicker;
interface ClientData {
    id: number,
    name: string
}

interface FilterData extends ClientData {
    clientId:number
}

interface StatusData extends FilterData {
    invoiceTypeId: number
}

interface CheckedProps {
    item: string;
    checked: boolean;
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';
export default function Invoices() {
    const [api, contextHolder] = notification.useNotification();
    const [clientList, setClientList] = useState<FilterData[]>([]);
    const [invoiceTypes, setInvoiceTypes] = useState<FilterData[]>([]);
    const [statusList, setStatusList] = useState<StatusData[]>([]);
    const [invoiceTypesLookup, setInvoiceTypesLookup] = useState<FilterData[]>([]);
    const [statusListLookup, setStatusListLookup] = useState<StatusData[]>([]);
    const [searchByDateRange, setDateRangeSelection] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState<string>();
    const [client, setClient] = useState<any | null>(null);
    const [startDate, setStartDate] = useState<string | any>('');
    const [endDate, setEndDate] = useState<string | any>('');
    const [status, setStatus] = useState<any | null>(null);
    const [invoiceType, setInvoiceType] = useState<any | null>(null);
    const [invoiceList, setInvoiceList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
    const [menuVisible, setMenuVisible] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [showModal, setModal] = useState<boolean>(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [modalFooter, setModalFooter] = useState<Element[]>([]);
    const [selectedAction, setSelectedAction] = useState<string>('');
    const [selectedInvoice, setSelectedInvoice] = useState<any>(null);
    const [invoiceData, setInvoiceData] = useState<any>(null);
    const [userAccess, setUserAccess] = useState({
        canGenerate: false,
        canAdd: false
    })
    //create Invoice


    const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
        api[type]({
            message: message,
            description: description
        });
    };
    const [position, setPosition] = useState({
        x: 0,
        y: 0
    });

    const columns: TableColumnsType<any> = [
        {
            title: 'Invoice Date',
            dataIndex: 'invoiceDate',
            render: (text) => dayjs(text).format('MM/DD/YYYY'),
            sorter: true
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoiceNumber',
            sorter: true
        },
        {
            title: 'Type',
            dataIndex: 'invoiceType',
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'invoiceStatus',
            sorter: true
        },
        {
            title: 'DOS Range',
            dataIndex: 'dosRange',
            sorter: true
        },
        {
            title: 'Include Previous Non-billable',
            dataIndex: 'includePreviousNonBillable',
            sorter: true
        },
        {
            title: 'View',
            dataIndex: 'invoiceNumber',
            render: (text, record) => (
                <Button onClick={() => openInvoiceInfoModal(record)} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.88)' }} type="primary">
                    <EyeOutlined/>
                </Button>
            )
        },
    ];

    const [isChecked, setChecked] = useState<CheckedProps[]>(
        columns.map((entry: any) => ({ item: entry.title, checked: !entry.hidden }))
    );

    const invoiceColumns = columns.map((item) => ({
        ...item,
        hidden: !isChecked.find(x => x.item === item.title)?.checked,
    }));

    function getFilterData(){
        getInvoiceFilters((res) => {
            if (res.hasError) {
                openNotificationWithIcon("error", "Something went wrong!!!", "");
                return;
            }
            if (res) {
                setClientList(res.clientList);
                setInvoiceTypesLookup(res.invoiceTypeList);
                setStatusListLookup(res.invoiceStatusList);
                setUserAccess({
                    ...userAccess,
                    canGenerate: res.canGenerate,
                    canAdd: res.canAdd
                })
            }
        })
    }

    function resetFilters() {
        setInvoiceType(null);
        setStatus(null);
        setClient(null)
        setStartDate('');
        setEndDate('');
        setInvoiceNumber(null);
    }

    function getInvoiceList() {
        setLoading(true);
        setInvoiceList([]);
        setModal(false);
        getInvoices({
            "pageIndex": currentPage,
            "pageSize": pageSize,
            "sortField": sortedInfo.order ? sortedInfo.field:null,
            "sortDescending": sortedInfo.order == 'descend',
            "clientId": client,
            "fromInvoiceDate": startDate && startDate != '' ? startDate : null,
            "toInvoiceDate": endDate && endDate != '' ? endDate: null,
            "invoiceNumber": invoiceNumber,
            "invoiceTypeId": invoiceType,
            "invoiceStatusId": status
        }, (res) => {
            if (res !== 'error') {
                !showTable && setShowTable(true);
                setInvoiceList(res.items);
                setLoading(false)
                setTotalPages(Math.ceil(res.totalRecords / (pageSize / pageSize)));
            } else {
                setInvoiceList([]);
                setLoading(false)
                setTotalPages(1);
            }
            
        })
    }
    function search() {
        if (!client) {
            openNotificationWithIcon("warning", "Please Select a Client", "");
            return;
        }
        if (pageSize == 0) {
            setPageSize(10);
        } else {
            currentPage == 1 ? getInvoiceList(): setCurrentPage(1);
        }
    }

    useEffect(() => {
        getFilterData();
    }, []);

    useEffect(() => {
        if (pageSize != 0)
            getInvoiceList();
    }, [currentPage, pageSize, sortedInfo]);

    useEffect(() => {
        if (client && client == invoiceData?.clientId && invoiceType == invoiceData?.invoiceTypeId) {
            search();
            setInvoiceData(null);
        }

        if (client && invoiceType) {
            setStatusList(statusListLookup.filter(x => x.clientId == client && x.invoiceTypeId == invoiceType));
        }
    }, [client, invoiceType])

    useEffect(() => {
        if (invoiceData) {
            if (invoiceData?.clientId) {
                setClient(invoiceData.clientId);
                setInvoiceTypes(invoiceTypesLookup.filter(x => x.clientId == invoiceData.clientId));
                setInvoiceType(invoiceData.invoiceTypeId);
                setModal(false)
            }
        }
    }, [invoiceData])

    const handleTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        setCurrentPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);
        setTotalPages(pagination.total ?? 100);
        setSortedInfo(sorter as SorterResult<any>);
    };

    useEffect(() => {
        if (selectedAction == 'edit') {
            setModal(true);
        }
    }, [selectedAction])

    useEffect(() => {
        if (selectedInvoice) {
            setSelectedAction('edit');
        }
    }, [selectedInvoice])

    function openInvoiceInfoModal(rec?: any) {
        if (rec) {
            setSelectedInvoice(rec);
        } else {
            if (client || invoiceType) {
                setInvoiceData({
                    filterType: invoiceType,
                    filterClient: client
                });
            }
            setSelectedAction('add');
            setModal(true);
        }
    }

    function clientSelected(e) {
        setClient(e);
        setInvoiceTypes(invoiceTypesLookup.filter(x => x.clientId == e));
    }

    function handleCallback(data) {
        if (data?.isInvalid) {
            openNotificationWithIcon("warning", data.errorMessage, "");
        } else if (data.isCancel) {
            closeModal(data);
        } else if (data.showMessage) {
            openNotificationWithIcon("success", data.message, "");
        } else {
            openNotificationWithIcon("success", data.message, "");
            if (selectedAction == 'edit') {
                search();
                setInvoiceData(null);
            } else {
                if (invoiceData?.filterClient == data.clientId && invoiceData?.filterType == data.invoiceTypeId) {
                    search();
                    setInvoiceData(null);
                } else {
                    setInvoiceData(data);
                }
            }
            setSelectedAction('');
        }
    }

    function closeModal(data) {
        setSelectedInvoice(null);
        if (selectedAction == 'edit') {
            if (!data.isCancel && client) {
                search();
            }
        }
        setSelectedAction('cancel');
        setInvoiceData(null);
        setModal(false);
    }

    return <ConfigProvider theme={{
        components: {
            Layout: { bodyBg: "#eff1f5", siderBg: "#fff" }
        }
    }} >
        {contextHolder}
        <Layout style={{
            padding: '20px',
            marginBottom: '5px',
            marginTop: '5px',            
        }}>
            <Drawer title="Invoice" destroyOnClose={true} maskClosable={false} onClose={closeModal} open={showModal} width={1000} styles={{ body: { background: '#eff1f5' } }}>
                <div>
                    <CreateInvoice clientList={clientList} invoiceTypeLookup={invoiceTypesLookup} selectedInvoice={selectedInvoice} action={selectedAction} filterData={invoiceData} modal={showModal} userAccess={userAccess} statusList={statusListLookup} parentCallback={handleCallback} />
                </div>
            </Drawer>
            <h3 style={{ textTransform: 'uppercase', marginTop:'0px' }}>Invoices</h3>
            <Flex justify='space-between' style={{ padding: "1rem", paddingBottom:"2rem", background: '#fff' }}>
                <label><SearchOutlined />&nbsp;Search</label>
                {userAccess.canAdd && <Button onClick={() => openInvoiceInfoModal()} style={{ marginLeft: '5px' }} type="primary" icon={<PlusOutlined />}>
                        Add Invoice
                    </Button>
                }
            </Flex>
            <Row type="flex" style={{ padding: '1rem', paddingTop: "0px", background: '#fff' }}>
                <Col span={6}>
                    <Select
                        style={{ width: '90%' }}
                        placeholder="Select a Client"
                        onChange={(e) => clientSelected(e)}
                        value={client}
                    >
                        {clientList && clientList.map(item => (
                            <Option key={item.id}>{item.name}</Option>
                        ))}
                    </Select>
                </Col>
                <Col span={6}>
                    <Select
                        style={{ width: '90%' }}
                        placeholder="Select a Type"
                        onChange={(e) => setInvoiceType(e)}
                        value={invoiceType}
                        disabled={!client}
                        allowClear
                    >
                        {invoiceTypes && invoiceTypes.map(item => (
                            <Option key={item.id}>{item.name}</Option>
                        ))}
                    </Select>
                </Col>
                <Col span={12}>
                    <RangePicker
                        placeholder={["From Invoice Date", "To Invoice Date"]}
                        style={{ width: '100%' }}
                        format="MM/DD/YYYY"
                        value={[startDate, endDate]}
                        onChange={(dates: any) => {
                            if (dates) {
                                setStartDate(dates[0]);
                                setEndDate(dates[1]);
                            } else {
                                setStartDate('');
                                setEndDate('');
                            }
                        }}
                    />
                </Col>
            </Row>
            <Row type="flex" style={{ padding: '1rem', paddingTop:"0px", background: '#fff' }}>
                <Col span={6}>
                    <Input value={invoiceNumber} placeholder="Invoice Number" style={{ width: '90%' }} allowClear onChange={(e) => setInvoiceNumber(e?.target?.value)} />
                </Col>
                <Col span={6}>
                    <Select
                        style={{ width: '90%' }}
                        placeholder="Select a Status"
                        onChange={(e) => setStatus(e)}
                        value={status}
                        disabled={!client || !invoiceType}
                        allowClear
                    >
                        {statusList && statusList.map(item => (
                            <Option key={item.id}>{item.name}</Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <Flex justify='end' style={{ marginBottom: "2rem", padding: "1rem", paddingTop: "0px", background: '#fff' }} gap='small'>
                <Button onClick={search} loading={loading} style={{ marginLeft: '5px' }} type="primary">
                    Search
                </Button>
                <Button onClick={resetFilters} style={{ backgroundColor: '#ffffff', borderColor: '#d9d9d9', marginLeft: '5px', color: 'rgba(0, 0, 0, 0.88)' }} type="primary">
                    Reset
                </Button>
            </Flex>
            <ColumnDropdown columns={columns} menuVisible={menuVisible} position={position} isChecked={isChecked} setChecked={setChecked} />
            { showTable && <Table
                style={{ overflowY: "auto", height: "calc(100vh - 164px)" }}
                dataSource={invoiceList}
                pagination={{
                    defaultCurrent: 1,
                    current: currentPage,
                    pageSize: pageSize,
                    total: totalPages,
                    showTotal: (total, range) => `${range[0] || 0 }-${range[1] || 1} of ${total} items`,
                    showSizeChanger: true,
                }}
                columns={invoiceColumns}
                onChange={handleTableChange}
                rowKey="invoiceNumber"
                loading={{ indicator: <LoaderSpinner size={24} topMargin="10px" />, spinning: loading }}
                onHeaderRow={(columns, index) => {
                    return {
                        onContextMenu: (event) => {
                            event.preventDefault();
                            if (!menuVisible) {
                                document.addEventListener(`click`, function onClickOutside() {
                                    setMenuVisible(false);
                                    document.removeEventListener(`click`, onClickOutside);
                                });
                            }
                            setMenuVisible(true);
                            setPosition({ x: event.clientX, y: event.clientY });
                        }
                    };
                }}
             /> }
        </Layout>
    </ConfigProvider>
}